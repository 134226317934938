/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { footLogo } from "../../Constant/images";
import { db } from '../../api/firebase-config';
import { collection, addDoc } from 'firebase/firestore';

const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email) {
      try {
        await addDoc(collection(db, 'newsletterSubscriptions'), {
          email: email,
          subscribedAt: new Date(),
        });
        alert("Nous vous remercions. Les informations détaillées concernant nos circuits vous seront envoyées prochainement.");
      } catch (error) {
        console.error('Error adding document: ', error);
        alert('Failed to subscribe. Please try again later.');
      }

      setEmail('');
    } else {
      alert('Merci d\'entrez une adresse email valide.');
    }
  };
  return (
    <div id="footer">
      <div className="footer">
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-12">
                <div className="footer-widget">
                  <img
                    src={footLogo}
                    className="foot-logo"
                    alt="footer logo"
                    width="150"
                  />

                  <div className="cont_info">
                    <p>
                      <i className="material-icons">phone</i>
                      <span>+33 7 75 73 55 79</span>
                    </p>

                    <p>
                      <i className="material-icons">email</i>
                      <span>Contact@terangatours.com</span>
                    </p>

                    <p>
                      <i className="material-icons">location_on</i>
                      <span>
                        25 Rue de Ponthieu, Paris 75008
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-12">
                <div className="footer-widget">
                  <h3 className="fot-title">Retrouvez nous sur</h3>
                  <ul>
                    <li>
                      <a target="blank" href="https://www.facebook.com/Terangatours1/">Facebook</a>
                    </li>
                    <li>
                      <a target="blank" href="https://www.instagram.com/terangatours2/">Instagram</a>
                    </li>
                    <li>
                      <a target="blank" href="https://twitter.com/terangatours1">Twitter</a>
                    </li>
                    <li>
                      <a target="blank" href="https://www.tiktok.com/@terangatours1">Tiktok</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-4 col-12">
                <div className="footer-widget">
                  <h3 className="fot-title">S'inscrire à la Newsletter</h3>
                  <p>
                    Veuillez saisir votre adresse e-mail ci-dessous pour ne manquer aucune offre passionnante !
                  </p>
                  <form className="subscribe-form mt-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control form--control"
                        name="email"
                        placeholder="Entrez votre email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button className="btn-contact-bg" type="submit">
                      Envoyez
                    </button>
                  </form>
                </div>
              </div>

            </div>
          </div>

          <div className="footer-bottom text-center">
            <p className="copyright_text">
              Copyright @ 2024 <a href="#">TerangaTours</a> all right reserved.
            </p>
            <ul className="foot_social_link">
              <li>
                <a target="blank" href="https://www.facebook.com/Terangatours1/">
                  <i className="fab fa-facebook"></i>
                </a>
              </li>
              <li>
                <a target="blank" href="https://twitter.com/terangatours1">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a target="blank" href="https://www.instagram.com/terangatours2/">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a target="blank" href="https://www.tiktok.com/@terangatours1">
                  <i className="fab fa-tiktok"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
