/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { chooseImg } from "../../Constant/images";
import PopUpScreen from "../../PopUp/PopUpScreen";

const ChooseUs = () => {
  const [activePopUp, setActivePopUp] = useState(false);

  const body = document.querySelector("body");

  activePopUp ? body.classList.add("active") : body.classList.remove("active");
  return (
    <section className="why_choose section-padding">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="single_choose mrb40">
                  <i className="material-icons">local_activity</i>
                  <h3>TerangaTours</h3>
                  <p>
                    TerangaTours vous offre une variété de formules pour explorer le Sénégal en toute sécurité, grâce à notre équipe expérimentée cumulant 15 années d'expertise.  
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="single_choose mrb40">
                  <i className="material-icons">route</i>
                  <h3>Circuit</h3>
                  <p>
                    Découvrez le Sénégal à travers nos circuits personnalisés, incluant des destinations incontournables telles que Dakar, Saint-Louis, l'île de Gorée, Ile de Saloum, le Lac Rose, la reserve de Bandia, le désert à Lompoul et bien d'autres encore.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="single_choose">
                  <i className="material-icons">settings_accessibility</i>
                  <h3>Guide Privé</h3>
                  <p>
                    Laissez-vous guider à travers les merveilles du Sénégal. Notre équipe de guides experts vous accompagne de A à Z pour une expérience de voyage inoubliable.
                  </p>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="single_choose">
                  <i className="material-icons">location_on </i>
                  <h3>Localisation</h3>
                  <p>
                      Découvrez notre agence basée à Paris et Dakar, où nous nous engageons à assurer la sécurité et la satisfaction de chaque voyageur. Avec notre expertise et notre dévouement, votre tranquillité d'esprit est notre priorité absolue.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-12">
            <div className="choose_img">
              <img src={chooseImg} alt="Choose_Image" />
              <button
                className="pbtn"
                style={{ cursor: "pointer", outline: "none", border: "none" }}
                onClick={() => setActivePopUp(true)}
              >
                <i className="material-icons">slow_motion_video</i>
              </button>
              {activePopUp && (
                <PopUpScreen activePopUp={{ setActivePopUp }}>
                  <ReactPlayer
                    url={"https://youtu.be/PJChQbukdzI"}
                    playing
                    controls
                  />
                </PopUpScreen>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChooseUs;
