export const logo = require('../assets/img/logo.png')
export const slider1 = require('../assets/img/slider/1.jpg')
export const slider2 = require('../assets/img/slider/2.jpg')
export const slider3 = require('../assets/img/slider/3.jpg')
export const chooseImg = require('../assets/img/choose.jpg')
export const gallery1 = require('../assets/img/gallery/1.jpg')
export const gallery2 = require('../assets/img/gallery/2.jpg')
export const gallery3 = require('../assets/img/gallery/3.jpg')
export const gallery4 = require('../assets/img/gallery/4.jpg')
export const gallery5 = require('../assets/img/gallery/5.jpg')
export const gallery6 = require('../assets/img/gallery/6.jpg')
export const leftArrow = require('../assets/img/prev.gif')
export const rightArrow = require('../assets/img/next.gif')
export const packages1 = require('../assets/img/package/1.jpg')
export const packages2 = require('../assets/img/package/2.jpg')
export const packages3 = require('../assets/img/package/3.jpg')
export const clientBg = require('../assets/img/bg/client-bg.png')
export const review2 = require('../assets/img/review/2.png')
export const footLogo = require('../assets/img/foot-logo.png')
export const bgSection1 = require('../assets/img/bg/section-bg.jpg')
