// CookieContext.js
import React from 'react';
import Cookies from 'js-cookie';

const CookieContext = React.createContext();

const CookieProvider = ({ children }) => {
  const setCookie = (name, value, options) => {
    Cookies.set(name, value, options);
  };

  const getCookie = (name) => {
    return Cookies.get(name);
  };

  const removeCookie = (name) => {
    Cookies.remove(name);
  };

  return (
    <CookieContext.Provider value={[getCookie, setCookie, removeCookie]}>
      {children}
    </CookieContext.Provider>
  );
};

const useCookies = () => {
  const context = React.useContext(CookieContext);

  if (!context) {
    throw new Error('useCookies must be used within a CookieProvider');
  }

  return context;
};

export { CookieProvider, useCookies };
