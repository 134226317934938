import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CookieProvider } from './Components/Context/CookieContext'
import './Constant/importedCss'
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookieProvider>
      <App />
    </CookieProvider>
  </React.StrictMode>
);
