import React from "react";
import { slider1 } from "../../../Constant/images";

const SliderOne = () => {
  return (
    <div
      className="single_home_slide"
      style={{
        backgroundImage: `url(${slider1})`,
        backgroundSize: "cover",
      }}
    >
      <div className="slide_overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="hero-text">
                <p>Profitez du Sénégal avec les meilleurs guides touristiques</p>
                <h1>
                  Explorez le Sénégal en toute sécurité
                </h1>
                <a
                  href="#photos"
                  className="btn btn-default btn-home-bg"
                >
                  Découvrez Nous
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderOne;
