import React from "react";
import { Link } from "react-router-dom";
import { bgSection1 } from "../../Constant/images";

const ThankYou = () => {
  return (
    <>
      <section
        className="page_banner"
        style={{
          backgroundImage: `url(${bgSection1})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="banner_overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="section-blog-title">Diéré Dieuf</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="send-message-info section-padding">
        <div className="container text-center">
          <i className="ti-check"></i>
          <h4>Votre message a été envoyé avec succès.</h4>
          <p>
            <Link to={"/terangatours/home#home"} className="gallery-btn">
              Retour a l'acceuil
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
