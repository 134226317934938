import React from "react";
import { services } from "../../Constant/data";

const Services = () => {
  return (
    <section id="services" className="our_services">
      <div className="container">
        <div className="row">
          <div className="col-lg-12" data-aos="fade-up">
            <div className="section-title text-center">
              <span className="bg_title_text">Services</span>
              <h2>Services</h2>
              <p>Inclus dans l'offre</p>
            </div>
          </div>
        </div>

        <div className="row text-left">
          {services.map((item, index) => (
            <div
              className="col-xl-4 col-lg-6 col-12"
              key={Math.random() * index}
            >
              <div className="service">
                <div className="icon">
                  <i className="material-icons">{item.icon}</i>
                </div>
                <div className="ser_text">
                  <h4>{item.title}</h4>
                  <p>{item.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
