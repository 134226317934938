/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";

const ColorSwitcher = () => {
  const [activeColorPalette, setActiveColorPalette] = useState(false);
  const colors = [
    "green",
    "yellow",
    "red",
  ];

  const setTheme = (color) => {
    document.documentElement.style.setProperty("--color", color);
    document.documentElement.style.setProperty("--footer", color);
  };
  const setColor = (e) => {
    const currentColor = e.target.style.getPropertyValue("--color");
    setTheme(currentColor);
    localStorage.setItem("color", currentColor);
  };

  useEffect(() => {
    const currentColor = localStorage.getItem("color");
    setTheme(currentColor);
  }, []);
  return (
    <>
      <div
        id="style-switcher"
        style={
          activeColorPalette
            ? { left: 0, transition: "all .5s ease" }
            : { left: "-220px", transition: "all .5s ease" }
        }
      >
        <h2 onClick={() => setActiveColorPalette(!activeColorPalette)}>
          Color Palette
          <a>
            <i className="fa fa-cog fa-spin"></i>
          </a>
        </h2>
        <div>
          <ul className="colors" id="color1">
            {colors.map((color, index) => (
              <li key={index}>
                <a
                  style={{ "--color": color }}
                  onClick={setColor}
                  className="color_item"
                ></a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ColorSwitcher;
