import React from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { testimonialData } from "../../Constant/data";

const Testimonial = () => {
  return (
    <section className="testimonial">
      <div className="container">
        <div className="row">
          <div className="col-md-12" data-aos="fade-up">
            <div className="section-title text-center">
              <span className="bg_title_text">Avis</span>
              <h2>Avis</h2>
              <p>Ce que disent nos clients </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-block mx-auto text-center">
            <Swiper
              id="testimonial-slider"
              className="owl-carousel"
              slidesPerView={3}
              spaceBetween={30}
              loop
              modules={[Autoplay, Navigation]}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              navigation
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                300: {
                  slidesPerView: 1,
                },
              }}
            >
              {testimonialData.map((item, index) => (
                <SwiperSlide
                  className="single-testimonial"
                  key={index * Math.random()}
                >
                  <div className="test-img">
                    <img src={item.img} width="80" alt="review_image" />
                  </div>
                  <div className="testimonial-content">{item.desc}</div>

                  <div className="testimonial-bottom">
                    <h3 className="title">{item.title}</h3>
                    <span className="designation">{item.surname}</span>
                    <div className="test-rating">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <i className="icofont-star"></i>
                          <i className="icofont-star"></i>
                          <i className="icofont-star"></i>
                          <i className="icofont-star"></i>
                          <i className="icofont-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
