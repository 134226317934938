import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { specialPackageData } from "../../Constant/data";
import  CircuitPdf  from "../../assets/img/brochure.pdf"

const SpecialPackage = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState({});

  const handlePackageClick = (pkg) => {
    setSelectedPackage(pkg);
    setShowModal(true);
  };

  return (
    <section id="forfait" className="special_package section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12" data-aos="fade-up">
            <div className="section-title text-center">
              <span className="bg_title_text">Forfait</span>
              <h2>Forfait Populaire</h2>
              <p>Les Meilleurs Ventes</p>
            </div>
          </div>
        </div>

        <div className="row text-left">
          {specialPackageData.map((item, index) => (
            <div
              className="col-xl-4 col-lg-6 col-12"
              key={index}
              style={{ cursor: "pointer" }}
              onClick={() => handlePackageClick(item)}
            >
              <div className="single_package">
                <div className="pack_image">
                  <img className="img-fluid" src={item.img} alt={item.title} />
                </div>
                <div className="package-hover">
                  <span className="pack_price">{item.price}</span>
                  <div className="tour-rating">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  <h5>{item.title}</h5>
                  <div className="pac-meta">
                    <span className="psingle-meta">
                      <i className="icofont-clock-time"></i>
                      {item.duration}
                    </span>
                    <span className="psingle-meta">
                      <i className="icofont-location-arrow"></i>
                      {item.place}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPackage.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <span style={{ color: 'green' }}>Description du forfait :</span>
            <span dangerouslySetInnerHTML={{ __html: selectedPackage.description }}></span>
          </p>
          <p><span style={{ color: 'yellow' }}>Prix :</span> 1099€</p>
          <p><span style={{ color: 'red' }}>Durée :</span> {selectedPackage.duration}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fermer
          </Button>
          <a href={CircuitPdf} download>
            <Button variant="primary">
              Télécharger la brochure
            </Button>
          </a>
        </Modal.Footer>
      </Modal>
    </section>
  );
};

export default SpecialPackage;
