import React, { useState } from "react";
import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
const style = {
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: "#0000009d",
  zIndex: 1040,
};
const closeBtn = {
  position: "absolute",
  top: "10px",
  right: "10px",
  fontSize: "20px",
  zIndex: 100,
};

const PopUpScreen = ({ children, activePopUp }) => {
  const { setActivePopUp } = activePopUp;
  const [loading, setLoading] = useState(true);
  const color = document.documentElement.style.getPropertyValue("--color");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div style={style}>
      <button
        className="close_btn btn  text-white"
        onClick={() => {
          setActivePopUp(false)
        }}
        style={closeBtn}
      >
        x
      </button>
      <div className="item">
        {!loading ? children : <ClipLoader color={color} />}
      </div>
    </div>
  );
};

export default PopUpScreen;
