/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import PopUpScreen from "../../PopUp/PopUpScreen";
import LightBox from "../../PopUp/LightBox";
import { galleryData } from "../../Constant/data";

const Gallery = () => {
  const [lightImg, setLightImg] = useState();
  const [totalImg, setTotalImg] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activePopUp, setActivePopUp] = useState(false);

  const body = document.querySelector("body");

  activePopUp ? body.classList.add("active") : body.classList.remove("active");

  // imgs lightbox functionality

  const handleLightBox = (data, index) => {
    setLightImg(data.img);
    setCurrentIndex(index);
    setTotalImg(galleryData.length);
  };

  const handelRight = () => {
    const totalLength = galleryData.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = galleryData[0].img;
      setLightImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = galleryData.filter((item) => {
      return galleryData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].img;
    setLightImg(newItem);
    setCurrentIndex(newIndex);
  };

  const handleLeft = () => {
    const totalLength = galleryData.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = galleryData[totalLength - 1].img;
      setLightImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = galleryData.filter((item) => {
      return galleryData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].img;
    setLightImg(newItem);
    setCurrentIndex(newIndex);
  };
  return (
    <section id="photos" className="our_gallery section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12" data-aos="fade-up">
            <div className="section-title text-center">
              <span className="bg_title_text">Photos</span>
              <h2>Tours Photos </h2>
              <p>Meilleures photos de nos touristes</p>
            </div>
          </div>
        </div>
        <div className="portfolio-grid">
          <div className="row">
            {galleryData.map((item, index) => (
              <div
                className="col-xl-4 col-lg-6 col-12 portfolio-item"
                key={Math.random() * index}
              >
                <div className="single-gallery">
                  <img src={item.img} alt="gallery_Image" />
                  <a
                    className="gallery_enlarge_icon"
                    onClick={() => {
                      setActivePopUp(true);
                      handleLightBox(item, index);
                    }}
                  >
                    <i className={item.icon}></i>
                  </a>
                  <h4>{item.title}</h4>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {activePopUp && (
        <PopUpScreen activePopUp={{ setActivePopUp }}>
          <LightBox
            img={lightImg}
            totalImgNum={totalImg}
            currentIndex={currentIndex}
            handleRight={handelRight}
            handleLeft={handleLeft}
          />
        </PopUpScreen>
      )}
    </section>
  );
};

export default Gallery;
