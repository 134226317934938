import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { CookieProvider } from './Components/Context/CookieContext';
import Header from './Components/Header/Header'
import Home from "./Components/Home/Home";
import ColorSwitcher from "./Components/Pages/ColorSwitcher";
import Footer from "./Components/Pages/Footer";
import ThankYou from "./Components/Pages/ThankYou";
import TopControl from "./Components/Pages/TopControl";
import { analytics } from './api/firebase-config';
import { logEvent } from "firebase/analytics";

function App() {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const cookieConsent = sessionStorage.getItem('cookieConsent');
    if (cookieConsent) {
      setShowModal(false);
    }
  }, []);

  const handleAccept = () => {
    setShowModal(false);
    sessionStorage.setItem('cookieConsent', 'accepted');
    logEvent(analytics, 'accepted_cookies');
  };

  const handleReject = () => {
    setShowModal(false);
    sessionStorage.setItem('cookieConsent', 'rejected');
    logEvent(analytics, 'rejected_cookies');
  };

  function UseAnalytics() {
    let location = useLocation();
    useEffect(() => {
      logEvent(analytics, 'page_view', {
        page_path: location.pathname
      });
    }, [location]);
    return null;
  }

  return (
    <>
      <CookieProvider>
        <BrowserRouter>
        <UseAnalytics />
          <Header />
          <ColorSwitcher />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/terangatours/home" element={<Home />} />
            <Route path="/terangatours/home/thanks" element={<ThankYou />} />
          </Routes>

          <Footer />
          <TopControl />
        </BrowserRouter>
      </CookieProvider>

      <div style={{ position: 'fixed', zIndex:9999 , bottom: '0', left: '0', right: '0', padding: '1rem', backgroundColor: '#666', display: showModal ? 'block' : 'none' }}>
        <p>Ce site utilise des cookies pour améliorer votre expérience.</p>
        <button className='btn btn-default btn-home-bg' style={{ marginRight: '10px' }} onClick={handleAccept}>Accepter</button>
        <button className='btn btn-default btn-home-bg' onClick={handleReject}>Refuser</button>
      </div>
    </>
  );
}

export default App;
