import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../../api/firebase-config";
import { Timestamp } from "firebase/firestore";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [peopleCount, setPeopleCount] = useState("");
  const [duration, setDuration] = useState("");
  const [pack, setPack] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const naviget = useNavigate();

  useEffect(() => {
    emailjs.init("FWvawBvqepYogmIQY");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Ajout des données de formulaire dans Firestore
      await addDoc(collection(db, "users"), {
        name,
        email,
        peopleCount,
        phone,
        duration,
        pack,
        message,
        createdAt: Timestamp.now(),
      });

      // Paramètres de votre template EmailJS
      const templateParams = {
        name,
        email,
        peopleCount,
        phone,
        duration,
        pack,
        message,
      };

      // Envoi de l'email via EmailJS
      await emailjs.send("service_dirune2", "template_1xs1awg", templateParams, "FWvawBvqepYogmIQY")
        .then(response => {
          console.log("Email sent successfully!", response.status, response.text);
          setEmail("");
          setName("");
          setDuration("");
          setMessage("");
          setPack("");
          setPhone("");
          setPeopleCount("");
          naviget("/terangatours/home/thanks");
        }, err => {
          console.error("Failed to send email", err);
          setError(true);
        });
    } catch (error) {
      console.error("Error adding message to Firestore", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-xl-8 col-12">
        <div className="contact">
          <form onSubmit={handleSubmit} id="contact-form">
            <div className="row">
              <div className="form-group col-xl-4">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  id="first-name"
                  placeholder="Nom"
                  required="required"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={error ? { borderColor: "red" } : null}
                />
              </div>
              <div className="form-group col-xl-4">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="first-email"
                  placeholder="Email"
                  required="required"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={error ? { borderColor: "red" } : null}
                />
              </div>

              <div className="form-group col-xl-4">
                <input
                  type="number"
                  name="peopleCount"
                  className="form-control"
                  placeholder="Nombre de personnes"
                  style={error ? { borderColor: "red" } : null}
                />
              </div>

              <div className="form-group col-xl-5 mb-4 ">
                <PhoneInput
                  country={'fr'}
                  value={phone}
                  onChange={setPhone}
                  placeholder="Téléphone"
                  style={error ? { borderColor: "red" } : null}
                  containerStyle={{ width: '100%' }}
                  inputStyle={{ width: '100%', paddingLeft: '48px' }}
                />
              </div>

              <div className="form-group col-xl-12">
                <select
                  id="duration"
                  name="duration"
                  className="form-control"
                  onChange={(e) => setDuration(e.target.value)}
                  style={error ? { borderColor: "red" } : null}
                >
                  <option value="">Sélectionnez une durée...</option>
                  <option value="court">Court (7 jours)</option>
                  <option value="moyen">Moyen (7-14 jours)</option>
                  <option value="long">Long (plus de 14 jours)</option>
                </select>
              </div>

              <div className="form-group col-xl-12">
                <select
                  id="packageSelect"
                  name="pack"
                  className="form-control"
                  onChange={(e) => setPack(e.target.value)}
                  style={error ? { borderColor: "red" } : null}
                >
                  <option value="">Sélectionnez un forfait...</option>
                  <option value="decouverte">Découverte</option>
                  <option value="aLaCarte">À la Carte</option>
                  <option value="oneLife">Life Style</option>
                </select>
              </div>

              <div className="form-group col-xl-12">
                <textarea
                  rows="6"
                  name="message"
                  className="form-control"
                  id="description"
                  placeholder="Votre message"
                  required="required"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={error ? { borderColor: "red" } : null}
                ></textarea>
              </div>
              <div className="col-xl-12">
                <div className="actions text-center">
                  <input
                    type="submit"
                    name="submit"
                    id="submitButton"
                    className="btn btn-lg btn-contact-bg"
                    value={loading ? "envoie..." : "Envoyez"}
                    style={error ? { borderColor: "red" } : null}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Form;