import React from "react";
import { slider2 } from "../../../Constant/images";

const SliderTwo = () => {
  return (
    <div
      className="single_home_slide"
      style={{
        backgroundImage: `url(${slider2})`,
        backgroundSize: "cover",
      }}
    >
      <div className="slide_overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="hero-text">
                <p>Une équipe d'experts professionnels du voyage </p>
                <h1>
                  Faites confiance a nos experts
                </h1>

                <a
                  href="#contact"
                  className="btn btn-default btn-home-bg"
                >
                  Demande de devis
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderTwo;
