import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../Constant/images";
import MobileMenu from "./MobileMenu";
import Navbar from "./Navbar";

const Header = () => {
  const scrollNav = useRef();

  const menuItem = [
    "home",
    "services",
    "photos",
    "forfait",
    "contact",
  ];

  useEffect(() => {
    // scrolling nav
    window.addEventListener("scroll", () => {
      let windowScroll = window.scrollY > 100;
      scrollNav.current.classList.toggle("navbar-fixed", windowScroll);
    });
  }, []);

  return (
    <div
      id="navigation"
      className="fixed-top navbar-light bg-faded site-navigation"
      ref={scrollNav}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-2 col-md-3 col-sm-4">
            <div className="site-logo">
              <Link
                to={"/terangatours/home"}
                onClick={() => (document.documentElement.scrollTop = 0)}
              >
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 responsive_menu_wrapper">
            <div className="header_right">
              <Navbar menuItem={menuItem} />
              <MobileMenu menuItem={menuItem} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
