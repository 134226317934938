import React from "react";
import ChooseUs from "../Pages/ChooseUs";
import Contact from "../Pages/Contact/Contact";
import Gallery from "../Pages/Gallery";
import Hero from "../Pages/Hero/Hero";
import Services from "../Pages/Services";
import SpecialPackage from "../Pages/SpecialPackage";
import Testimonial from "../Pages/Testimonial";

const Home = () => {
  return (
    <>
      <Hero />
      <ChooseUs />
      <Services />
      <Gallery />
      <SpecialPackage />
      <Testimonial />
      <Contact />
    </>
  );
};

export default Home;
