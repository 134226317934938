import { gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, packages1, packages2, packages3,review2} from './images'
export const services = [
    {
        icon: 'king_bed',
        title: 'Hebergement',
        desc: "Profitez d'un séjour sécurisé dans nos hôtels partenaires, offrant des chambres doubles équipées de climatisation, TV, Wi-Fi, et conciergerie."
    },
    {
        icon: 'fastfood',
        title: 'Repas',
        desc: 'Savourez des repas délicieux, avec nos restaurants partenaires, inclus dans votre formule, alliant saveurs locales et internationales.'
    },
    {
        icon: 'local_taxi',
        title: 'Transport',
        desc: "Profitez d'un transport en mini-car ou voiture inclus, du début à la fin de votre séjour, pour un déplacement sans tracas."
    },
    {
        icon: 'maps',
        title: 'Circuit',
        desc: "Découvrez le Sénégal à travers des circuits personnalisés, vous emmenant de villes en villages pour une immersion totale et une expérience inoubliable."
    },
    {
        icon: 'medication',
        title: 'Assurance',
        desc: "Notre engagement pour votre sérénité : une assurance voyageur complète, vous assurant un séjour paisible et sécurisé du début à la fin."
    },
    {
        icon: 'support_agent',
        title: 'Assistance',
        desc: "Bénéficiez d'une assistance 24/7 avec nos guides et notre équipe, disponibles à tout moment pendant votre séjour."
    },
]

export const galleryData = [
    {
        img: gallery1,
        title: 'Statue de la Renaissance',
        icon: 'icofont-long-arrow-right',
    },
    {
        img: gallery2,
        title: 'Village des Pêcheurs',
        icon: 'icofont-long-arrow-right',

    },
    {
        img: gallery3,
        title: 'Désert de Lompoul',
        icon: 'icofont-long-arrow-right',

    },
    {
        img: gallery4,
        title: 'Lac Rose',
        icon: 'icofont-long-arrow-right',

    },
    {
        img: gallery5,
        title: 'Rue de Saint-Louis,',
        icon: 'icofont-long-arrow-right',

    },
    {
        img: gallery6,
        title: 'Village Peul ',
        icon: 'icofont-long-arrow-right',

    }
]

export const specialPackageData = [
    {
        img: packages1,
        price: 'Détails',
        description: "Ce forfait est spécialement conçu pour les personnes souhaitant découvrir l'authenticité du Sénégal. Il comprend un guide touristique, le transport dès votre arrivée à l'aéroport et tout au long de votre séjour jusqu'à votre départ. Les repas du petit-déjeuner, du déjeuner et du dîner sont inclus, ainsi que la participation à des activités préalablement définies dans nos circuits. Une assistance est disponible 24 heures sur 24, 7 jours sur 7, pour garantir votre tranquillité d'esprit à tout moment.",
        title: 'Forfait Découverte',
        duration: '7 Jours',
    },
    {
        img: packages2,
        price: 'Détails',
        description: "Ce forfait est conçu pour les personnes désirant un circuit personnalisé. Nous vous proposons une liste d'activités à travers tout le Sénégal, et vous composez votre circuit sur mesure. Nous prendrons en charge le transport, l'hébergement et les repas. Un guide personnel sera à votre disposition pour assurer un séjour agréable, disponible 24 heures sur 24, 7 jours sur 7.",
        title: 'Forfait a la carte',
        duration: '7 Jours',
    },
    {
        img: packages3,
        price: 'Détails',
        description: "Le forfait LifeStyle, comme son nom l'indique, est dédié à ceux désirant explorer le Sénégal sous ses aspects les plus divertissants. Il inclut des séjours dans des hôtels et restaurants luxueux, la découverte de lieux branchés, ainsi que des sites offrant des vues à couper le souffle. Parmi les activités proposées, vivez l'expérience du quad sur les dunes du Lac Rose, des dîners sur les rooftops les plus prisés du pays... Pour vous garantir une sérénité absolue, notre guide reste à votre disposition 24 heures sur 24, 7 jours sur 7.",
        title: 'Forfait Life Style',
        duration: '7 Jours',
    },
]

export const testimonialData = [
    {
        img: review2,
        desc: "TerangaTours a transformé notre voyage en une aventure mémorable ! Notre guide Mamadou était incroyablement compétent et passionné. Chaque détail était parfaitement organisé. Vivement recommandé !",
        title: 'Sophia A.',
        surname: ''
    },
    {
        img: review2,
        desc: "Une expérience exceptionnelle. Des paysages à couper le souffle, des rencontres authentiques et un service irréprochable. Je n'oublierai jamais ce voyage !",
        title: 'Joselyne L.',
        surname: ''
    },
    {
        img: review2,
        desc: "Teranga Tours a dépassé toutes nos attentes ! Leur attention aux détails et leur connaissance de la région nous ont permis de découvrir des joyaux cachés. Un grand merci à toute l'équipe !",
        title: 'Namir B.',
        surname: ''
    },
    {
        img: review2,
        desc: "Voyager avec TerangaTours a été une révélation. Leurs circuits personnalisés sont incroyables et nous avons vraiment senti que nous explorions le cœur du pays. Une entreprise à soutenir sans hésitation !",
        title: 'Jean-Claude M.',
        surname: ''
    },
    {
        img: review2,
        desc: "Merci TerangaTours pour cette expérience inoubliable. Le guide était fantastique, partageant non seulement des informations sur les sites, mais aussi la culture et les traditions locales. Une vraie aventure humaine !",
        title: 'Marie L.',
        surname: ''
    },
    {
        img: review2,
        desc: "J'ai été époustouflée par la qualité du service offert par TerangaTours. Non seulement le circuit était parfaitement organisé, mais le respect et la valorisation des communautés locales m'ont vraiment marquée. Je recommande vivement !",
        title: 'Namir Bakri',
        surname: ''
    },
]
