import React from "react";
import { useEffect } from "react";
import { useRef } from "react";

const TopControl = () => {
  const rocket = useRef();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let windowScroll = window.scrollY > 150;
      rocket.current.classList.toggle("showRocket", windowScroll);
    });
  }, []);
  return (
    <div
      ref={rocket}
      id="topcontrol"
      className="topcontrol"
      title=""
      style={{
        position: "fixed",
        bottom: "5px",
        right: "5px",
        cursor: "pointer",
      }}
      onClick={() => (document.documentElement.scrollTop = 0)}
    >
      <i className="icofont-rocket-alt-1"></i>
    </div>
  );
};

export default TopControl;
