/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState } from "react";

const MobileMenu = ({ menuItem }) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const handleItemClick = (item) => {
    setActiveMenu(!activeMenu); // Ferme le menu mobile
    document.getElementById(item).scrollIntoView({
      behavior: 'smooth' // Défilement doux jusqu'à l'élément
    });
  };

  return (
    <div id="mobile_menu">
      <div className="slicknav_menu">
        <a
          style={{ cursor: "pointer" }}
          onClick={() => setActiveMenu(!activeMenu)}
          className="slicknav_btn"
        >
          <span className="slicknav_nav_icon fas fa-bars"></span>
        </a>
        <nav className={activeMenu ? "slicknav_nav activeSlicknav" : "slicknav_nav"}>
          <ul>
            {menuItem.map((item, index) => (
              <li key={index}>
                <a
                  href={`#${item}`}
                  onClick={() => handleItemClick(item)}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)} {/* Capitalize the item name */}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
