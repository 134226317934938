import React from "react";
import { slider3 } from "../../../Constant/images";

const SliderThree = () => {
  return (
    <div
      className="single_home_slide"
      style={{
        backgroundImage: `url(${slider3})`,
        backgroundSize: "cover",
      }}
    >
      <div className="slide_overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="hero-text">
                <p>Personnalisez votre séjour </p>
                <h1>
                  Vivez un séjour inoubliable au pays de la Teranga
                </h1>
                <a
                  href="#forfait"
                  className="btn btn-default btn-home-bg"
                >
                  Nos Circuits
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderThree;
