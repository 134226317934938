import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics"; // Importez getAnalytics pour Google Analytics

const firebaseConfig = {
    apiKey: "AIzaSyB-wFK7cxbVRN1Hn1u0uKgS6twlPULUMW0",
    authDomain: "terangatours-8008e.firebaseapp.com",
    projectId: "terangatours-8008e",
    storageBucket: "terangatours-8008e.appspot.com",
    messagingSenderId: "977295034386",
    appId: "1:977295034386:web:b3652fc91a0cfff05a30cb",
    measurementId: "G-SC06DCM9F9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)

export const analytics = getAnalytics(app);
