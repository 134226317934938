import React from "react";
import Form from "./Form";

const Contact = () => {
  return (
    <section id="contact" className="contact_area">
      <div className="container">
        <div className="row">
          <div className="col-md-12" data-aos="fade-up">
            <div className="section-title text-center">
              <span className="bg_title_text">Contact</span>
              <h2>Nous Contactez</h2>
              <p><br />
                Demande de devis
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Form />
      </div>
    </section>
  );
};

export default Contact;
