import React from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderOne from "./SliderOne";
import SliderThree from "./SliderThree";
import SliderTwo from "./SliderTwo";
const Hero = () => {
  return (
    <section id="home" className="home-slider">
      <Swiper
        className="welcome-slider-area"
        spaceBetween={0}
        slidesPerView={1}
        modules={[Autoplay, Navigation]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        navigation
        loop
      >
        <SwiperSlide>
          <SliderOne />
        </SwiperSlide>
        <SwiperSlide>
          <SliderTwo />
        </SwiperSlide>
        <SwiperSlide>
          <SliderThree />
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Hero;
